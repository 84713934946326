export type VoiceResponseQuestionData = {
    id: string
    questionEn: string
    questionEs: string
    questionAudioEn?: string
    questionAudioEs?: string
    moduleNumber: number
}

export const voiceResponseQuestions: VoiceResponseQuestionData[] = [
    {
        id: 'voiceResponseQuestion1',
        questionEn:
            'How do you help yourself when you have to wait to play with your parents?',
        questionEs:
            '¿Cómo te ayudas a ti mismo o a ti misma cuando tienes que esperar para jugar con tus padres?',
        moduleNumber: 2,
        questionAudioEn:
            '/assets/mysibtime/audio/conversation-bank/E_M1_Audio_Q1.mp3',
        questionAudioEs:
            '/assets/mysibtime/audio/conversation-bank/S_M1_Audio_Q1.mp3',
    },
    {
        id: 'voiceResponseQuestion2',
        questionEn: 'How can you let your parents know what you want to play?',
        questionEs:
            '¿Cómo puedes hacerles saber a tus padres qué quieres jugar?',
        moduleNumber: 2,
        questionAudioEn:
            '/assets/mysibtime/audio/conversation-bank/E_M1_Audio_Q2.mp3',
        questionAudioEs:
            '/assets/mysibtime/audio/conversation-bank/S_M1_Audio_Q2.mp3',
    },
    {
        id: 'voiceResponseQuestion3',
        questionEn: 'How do you feel when you get to choose what to play?',
        questionEs: '¿Cómo te sientes cuando puedes escoger el juego?',
        moduleNumber: 2,
        questionAudioEn:
            '/assets/mysibtime/audio/conversation-bank/E_M1_Audio_Q3.mp3',
        questionAudioEs:
            '/assets/mysibtime/audio/conversation-bank/S_M1_Audio_Q3.mp3',
    },
    {
        id: 'voiceResponseQuestion4',
        questionEn: 'How does your body feel when you are worried?',
        questionEs:
            '¿Cómo se siente tu cuerpo cuando estás preocupado o preocupada?',
        moduleNumber: 3,
        questionAudioEn:
            '/assets/mysibtime/audio/conversation-bank/E_M2_Audio_Q1.mp3',
        questionAudioEs:
            '/assets/mysibtime/audio/conversation-bank/S_M2_Audio_Q1.mp3',
    },
    {
        id: 'voiceResponseQuestion5',
        questionEn: 'What can you do when you feel worried about something?',
        questionEs:
            '¿Qué puedes hacer cuando te sientes preocupado o preocupada?',
        moduleNumber: 3,
        questionAudioEn:
            '/assets/mysibtime/audio/conversation-bank/E_M2_Audio_Q2.mp3',
        questionAudioEs:
            '/assets/mysibtime/audio/conversation-bank/S_M2_Audio_Q2.mp3',
    },
    {
        id: 'voiceResponseQuestion6',
        questionEn: 'How can your parents help you when you feel worried?',
        questionEs:
            '¿Cómo pueden ayudarte tus padres cuando te sientes preocupado o preocupada?',
        moduleNumber: 3,
        questionAudioEn:
            '/assets/mysibtime/audio/conversation-bank/E_M2_Audio_Q3.mp3',
        questionAudioEs:
            '/assets/mysibtime/audio/conversation-bank/S_M2_Audio_Q3.mp3',
    },
    {
        id: 'voiceResponseQuestion7',
        questionEn: 'What have you really liked learning with your parents?',
        questionEs: '¿Qué te ha gustado mucho aprender con tus padres?',
        moduleNumber: 4,
        questionAudioEn:
            '/assets/mysibtime/audio/conversation-bank/E_M3_Audio_Q1.mp3',
        questionAudioEs:
            '/assets/mysibtime/audio/conversation-bank/S_M3_Audio_Q1.mp3',
    },
    {
        id: 'voiceResponseQuestion8',
        questionEn:
            'How do you feel when you learn something new with your parents?',
        questionEs:
            '¿Cómo te sientes cuando aprendes algo nuevo con tus padres?',
        moduleNumber: 4,
        questionAudioEn:
            '/assets/mysibtime/audio/conversation-bank/E_M3_Audio_Q2.mp3',
        questionAudioEs:
            '/assets/mysibtime/audio/conversation-bank/S_M3_Audio_Q2.mp3',
    },
    {
        id: 'voiceResponseQuestion9',
        questionEn: 'What new thing would you like to learn with your parents?',
        questionEs: '¿Qué te gustaría aprender con tus padres?',
        moduleNumber: 4,
        questionAudioEn:
            '/assets/mysibtime/audio/conversation-bank/E_M3_Audio_Q3.mp3',
        questionAudioEs:
            '/assets/mysibtime/audio/conversation-bank/S_M3_Audio_Q3.mp3',
    },
    {
        id: 'voiceResponseQuestion10',
        questionEn: 'What jobs do you do at home?',
        questionEs: '¿Qué trabajos haces tú en casa?',
        moduleNumber: 5,
        questionAudioEn:
            '/assets/mysibtime/audio/conversation-bank/E_M4_Audio_Q1.mp3',
        questionAudioEs:
            '/assets/mysibtime/audio/conversation-bank/S_M4_Audio_Q1.mp3',
    },
    {
        id: 'voiceResponseQuestion11',
        questionEn: 'What jobs does your sibling do at home? ',
        questionEs: 'Qué trabajos hace tu hermano o hermana en casa?',
        moduleNumber: 5,
        questionAudioEn:
            '/assets/mysibtime/audio/conversation-bank/E_M4_Audio_Q2.mp3',
        questionAudioEs:
            '/assets/mysibtime/audio/conversation-bank/S_M4_Audio_Q2.mp3',
    },
    {
        id: 'voiceResponseQuestion12',
        questionEn: 'How do you feel when everyone helps out in your home?',
        questionEs: '¿Cómo te sientes cuando todos ayudan en tu casa?',
        moduleNumber: 5,
        questionAudioEn:
            '/assets/mysibtime/audio/conversation-bank/E_M4_Audio_Q3.mp3',
        questionAudioEs:
            '/assets/mysibtime/audio/conversation-bank/S_M4_Audio_Q3.mp3',
    },
    {
        id: 'voiceResponseQuestion13',
        questionEn: 'How does your family give you love and affection?',
        questionEs: '¿Cómo te dan amor y cariño en tu familia?',
        moduleNumber: 6,
        questionAudioEn:
            '/assets/mysibtime/audio/conversation-bank/E_M5_Audio_Q1.mp3',
        questionAudioEs:
            '/assets/mysibtime/audio/conversation-bank/S_M5_Audio_Q1.mp3',
    },
    {
        id: 'voiceResponseQuestion14',
        questionEn: 'What kind of love and affection do you like the most?',
        questionEs: '¿Qué tipo de amor y cariño te gusta más?',
        moduleNumber: 6,
        questionAudioEn:
            '/assets/mysibtime/audio/conversation-bank/E_M5_Audio_Q2.mp3',
        questionAudioEs:
            '/assets/mysibtime/audio/conversation-bank/S_M5_Audio_Q2.mp3',
    },
    {
        id: 'voiceResponseQuestion15',
        questionEn: 'How can you ask for more love and affection?',
        questionEs: '¿Cómo puedes pedir más amor y cariño?',
        moduleNumber: 6,
        questionAudioEn:
            '/assets/mysibtime/audio/conversation-bank/E_M5_Audio_Q3.mp3',
        questionAudioEs:
            '/assets/mysibtime/audio/conversation-bank/S_M5_Audio_Q3.mp3',
    },
    {
        id: 'voiceResponseQuestion16',
        questionEn:
            'Talk about a time you felt jealous of your sibling. What happened?',
        questionEs:
            'Piensa en una vez que te sentiste celoso o celosa de tu hermano o hermana. ¿Qué pasó?',
        moduleNumber: 7,
        questionAudioEn:
            '/assets/mysibtime/audio/conversation-bank/E_M6_Audio_Q1.mp3',
        questionAudioEs:
            '/assets/mysibtime/audio/conversation-bank/S_M6_Audio_Q1.mp3',
    },
    {
        id: 'voiceResponseQuestion17',
        questionEn: 'What does your body feel like when you feel jealous?',
        questionEs:
            '¿Qué sientes en tu cuerpo cuando te sientes celoso o celosa?',
        moduleNumber: 7,
        questionAudioEn:
            '/assets/mysibtime/audio/conversation-bank/E_M6_Audio_Q2.mp3',
        questionAudioEs:
            '/assets/mysibtime/audio/conversation-bank/S_M6_Audio_Q2.mp3',
    },
    {
        id: 'voiceResponseQuestion18',
        questionEn: 'What can you do when you feel jealous?',
        questionEs: '¿Qué puedes hacer cuando te sientes celoso o celosa?',
        moduleNumber: 7,
        questionAudioEn:
            '/assets/mysibtime/audio/conversation-bank/E_M6_Audio_Q3.mp3',
        questionAudioEs:
            '/assets/mysibtime/audio/conversation-bank/S_M6_Audio_Q3.mp3',
    },
    {
        id: 'voiceResponseQuestion19',
        questionEn:
            'Think about a time when you felt disappointed? What happened?',
        questionEs:
            '¿Piensa en una vez que te sentiste decepcionado o decepcionada? ¿Qué pasó?',
        moduleNumber: 8,
        questionAudioEn:
            '/assets/mysibtime/audio/conversation-bank/E_M7_Audio_Q1.mp3',
        questionAudioEs:
            '/assets/mysibtime/audio/conversation-bank/S_M7_Audio_Q1.mp3',
    },
    {
        id: 'voiceResponseQuestion20',
        questionEn: 'How does your body feel when you feel disappointed?',
        questionEs:
            '¿Cómo se siente tu cuerpo cuando te sientes decepcionado o decepcionada?',
        moduleNumber: 8,
        questionAudioEn:
            '/assets/mysibtime/audio/conversation-bank/E_M7_Audio_Q2.mp3',
        questionAudioEs:
            '/assets/mysibtime/audio/conversation-bank/S_M7_Audio_Q2.mp3',
    },
    {
        id: 'voiceResponseQuestion21',
        questionEn:
            'What helps you feel better after a disappointing experience?',
        questionEs: '¿Qué te ayuda a sentirte mejor?',
        moduleNumber: 8,
        questionAudioEn:
            '/assets/mysibtime/audio/conversation-bank/E_M7_Audio_Q3.mp3',
        questionAudioEs:
            '/assets/mysibtime/audio/conversation-bank/S_M7_Audio_Q3.mp3',
    },
    {
        id: 'voiceResponseQuestion22',
        questionEn: 'What do you like to do with your sibling?',
        questionEs: '¿Qué te gusta hacer con tu hermano o hermana?',
        moduleNumber: 9,
        questionAudioEn:
            '/assets/mysibtime/audio/conversation-bank/E_M8_Audio_Q1.mp3',
        questionAudioEs:
            '/assets/mysibtime/audio/conversation-bank/S_M8_Audio_Q1.mp3',
    },
    {
        id: 'voiceResponseQuestion23',
        questionEn: 'How are you and your sibling similar?',
        questionEs: '¿Cómo te pareces a tu hermano o hermana?',
        moduleNumber: 9,
        questionAudioEn:
            '/assets/mysibtime/audio/conversation-bank/E_M8_Audio_Q2.mp3',
        questionAudioEs:
            '/assets/mysibtime/audio/conversation-bank/S_M8_Audio_Q2.mp3',
    },
    {
        id: 'voiceResponseQuestion24',
        questionEn: 'How are you and your sibling different?',
        questionEs: '¿Cómo son diferentes, tú y tu hermano o hermana?',
        moduleNumber: 9,
        questionAudioEn:
            '/assets/mysibtime/audio/conversation-bank/E_M8_Audio_Q3.mp3',
        questionAudioEs:
            '/assets/mysibtime/audio/conversation-bank/S_M8_Audio_Q3.mp3',
    },
    {
        id: 'voiceResponseQuestion25',
        questionEn:
            'Think about a time when you felt mad at your sibling? What happened?',
        questionEs:
            'Piensa en una vez que te enojaste con tu hermano o hermana. ¿Qué pasó?',
        moduleNumber: 10,
        questionAudioEn:
            '/assets/mysibtime/audio/conversation-bank/E_M9_Audio_Q1.mp3',
        questionAudioEs:
            '/assets/mysibtime/audio/conversation-bank/S_M9_Audio_Q1.mp3',
    },
    {
        id: 'voiceResponseQuestion26',
        questionEn: 'What does your body feel like when you are mad?',
        questionEs: '¿Cómo se siente tu cuerpo cuando te enojas?',
        moduleNumber: 10,
        questionAudioEn:
            '/assets/mysibtime/audio/conversation-bank/E_M9_Audio_Q2.mp3',
        questionAudioEs:
            '/assets/mysibtime/audio/conversation-bank/S_M9_Audio_Q2.mp3',
    },
    {
        id: 'voiceResponseQuestion27',
        questionEn:
            'What can you do if you need help when you’re playing with your brother/sister?',
        questionEs:
            '¿Qué puedes hacer si necesitas ayuda cuando estás jugando con tu hermano o hermana?',
        moduleNumber: 10,
        questionAudioEn:
            '/assets/mysibtime/audio/conversation-bank/E_M9_Audio_Q3.mp3',
        questionAudioEs:
            '/assets/mysibtime/audio/conversation-bank/S_M9_Audio_Q3.mp3',
    },
    {
        id: 'voiceResponseQuestion28',
        questionEn: 'What do you love most about your sibling?',
        questionEs: '¿Qué es lo que más te gusta de tu hermano o hermana?',
        moduleNumber: 11,
        questionAudioEn:
            '/assets/mysibtime/audio/conversation-bank/E_M10_Audio_Q1.mp3',
        questionAudioEs:
            '/assets/mysibtime/audio/conversation-bank/S_M10_Audio_Q1.mp3',
    },
    {
        id: 'voiceResponseQuestion29',
        questionEn:
            'Have you ever felt confused about something your sibling said or did? What happened?',
        questionEs:
            '¿Alguna vez tu hermano o hermana hizo algo que te confundió? ¿Qué pasó?',
        moduleNumber: 11,
        questionAudioEn:
            '/assets/mysibtime/audio/conversation-bank/E_M10_Audio_Q2.mp3',
        questionAudioEs:
            '/assets/mysibtime/audio/conversation-bank/S_M10_Audio_Q2.mp3',
    },
    {
        id: 'voiceResponseQuestion30',
        questionEn:
            'What can you do when you feel confused about something your sibling says or does?',
        questionEs:
            '¿Qué puedes hacer cuando tu hermano o hermana hace algo que te confunde?',
        moduleNumber: 11,
        questionAudioEn:
            '/assets/mysibtime/audio/conversation-bank/E_M10_Audio_Q3.mp3',
        questionAudioEs:
            '/assets/mysibtime/audio/conversation-bank/S_M10_Audio_Q3.mp3',
    },
]
