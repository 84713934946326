import { Device, DeviceId, DeviceInfo } from '@capacitor/device'
import { Token } from '@capacitor/push-notifications'
import { useThrottleFn } from '@vueuse/core'
import { computed, readonly, Ref, ref } from 'vue'

import {
    AuthUser,
    auth,
    firestore,
    FirestoreFieldValue,
    serverTimestamp,
    deleteField,
    FirebaseDocumentSnapshot,
    FirestoreWriteBatch,
    arrayUnion,
} from '@/firebase-config'
import router from '@/router'

import useChats from '@/composables/global/use-chats'

import { logger } from '@/utils/debug'
import { docData, getCollectionDocs } from '@/utils/firestore'

import { Level } from '@/models/courses/level'
import { PageFeature } from '@/models/courses/levels/page'
import { Element } from '@/models/courses/levels/pages/element'
import { FeedbackMedia } from '@/models/courses/levels/pages/feedback-media'
import {
    User,
    DisplayMode,
    UserOption,
    UserStatus,
    UserProperties,
    SibTimeMoment,
} from '@/models/user'
import { RequiredMedia } from '@/models/users/required-media'

const debug = false

// Global refs for logged in user's auth and db info
const authUser: Ref<AuthUser | null> = ref(null)
const viewer: Ref<User | undefined> = ref()

const viewerDeviceInfo: Ref<DeviceInfo | undefined> = ref()
const viewerDeviceId: Ref<DeviceId | undefined> = ref()

const playedRequiredMedia = ref(new Set<string>())

// Stores all unsubscribe functions for listener cleanup
const unsubscribe = ref<() => void>()

const hasAutoplayEnabled = computed(() => {
    return viewer.value?.options?.includes('autoplay') ?? false
})

// Device
const isOnAndroid = computed(() => {
    return viewerDeviceInfo.value?.platform === 'android'
})

const isOnIos = computed(() => {
    return viewerDeviceInfo.value?.platform === 'ios'
})

const isOnWeb = computed(() => {
    return viewerDeviceInfo.value?.platform === 'web'
})

// Roles
const isAdmin = computed(() => {
    return viewer.value !== undefined && viewer.value.roles.includes('admin')
})

const isEditor = computed(() => {
    return viewer.value !== undefined && viewer.value.roles.includes('editor')
})

const isGuide = computed(() => {
    return viewer.value !== undefined && viewer.value.roles.includes('guide')
})

const isManager = computed(() => {
    return viewer.value !== undefined && viewer.value.roles.includes('manager')
})

const isParticipant = computed(() => {
    return viewer.value?.roles.length === 0
})

// Status
const isNew = computed(() => {
    return viewer.value !== undefined && viewer.value.status === 'new'
})

const isActive = computed(() => {
    return viewer.value !== undefined && viewer.value.status === 'active'
})

const isInactive = computed(() => {
    return viewer.value !== undefined && viewer.value.status === 'inactive'
})

const isCompleted = computed(() => {
    return viewer.value !== undefined && viewer.value.status === 'completed'
})

const isWithdrawn = computed(() => {
    return viewer.value !== undefined && viewer.value.status === 'withdrawn'
})

const isStaff = computed(() => {
    return viewer.value !== undefined && viewer.value.status === 'staff'
})

const viewersCourseIds = computed(() => {
    const coursesProxy = viewer.value?.courses // Needs unpacking to extract keys
    const courseIdsArray = Object.keys(Object.assign({}, coursesProxy))
    return courseIdsArray
})

const grantableRolesByViewer = computed(() => {
    const viewerRoles = viewer.value?.roles
    if (viewerRoles?.includes('admin'))
        return ['guide', 'editor', 'manager', 'admin']
    else if (viewerRoles?.includes('manager'))
        return ['guide', 'editor', 'manager']
    else return []
})

auth.onAuthStateChanged(async (user) => {
    logger(debug, 'Auth state changed:', user)
    authUser.value = user
})

export default function () {
    async function initViewer(userId: string) {
        logger(debug, userId)

        viewerDeviceInfo.value = await Device.getInfo()
        viewerDeviceId.value = await Device.getId()

        const viewerSnapshot = await firestore
            .collection('users')
            .doc(userId)
            .get()
        if (viewerSnapshot.exists) {
            viewer.value = docData<User>(viewerSnapshot)

            const unsubFromSibtime: () => void = firestore
                .collection('users')
                .doc(userId)
                .onSnapshot((userSnapshot: FirebaseDocumentSnapshot) => {
                    viewer.value = docData<User>(userSnapshot)
                })

            unsubscribe.value = unsubFromSibtime
        } else {
            // Auth user exists but no user data: create user doc from code
            await router.push({ name: 'LoginPageCreateAccount' })
            return { exists: false }
        }

        const playedRequiredMediaDocs = await firestore
            .collection('users')
            .doc(userId)
            .collection('required-media')
            .get()
            .then(getCollectionDocs<RequiredMedia>)

        playedRequiredMediaDocs.forEach(({ id }) =>
            playedRequiredMedia.value.add(id)
        )

        return { exists: true }
    }

    function isRequiredMediaPlayed(
        mediaElement: Element | FeedbackMedia | undefined
    ) {
        logger(debug, mediaElement)
        const notRequired = mediaElement === undefined || !mediaElement.required
        return notRequired || playedRequiredMedia.value.has(mediaElement.id)
    }

    async function setRequiredMediaPlayed(mediaId: string) {
        logger(debug, mediaId)
        if (viewer.value === undefined) return

        // Don't write if entry already exists
        if (playedRequiredMedia.value.has(mediaId)) return

        await firestore
            .collection('users')
            .doc(viewer.value.id)
            .collection('required-media')
            .doc(mediaId)
            .set({ id: mediaId, playedAt: serverTimestamp() })

        playedRequiredMedia.value.add(mediaId)
    }

    async function updateActiveCourse(courseId: string) {
        logger(debug, courseId)
        if (
            viewer.value?.id === undefined ||
            viewer.value.activeCourse === courseId
        ) {
            return Promise.resolve()
        }

        await firestore
            .collection('users')
            .doc(viewer.value.id)
            .update({ activeCourse: courseId })

        viewer.value.activeCourse = courseId
    }

    async function updateCourseAdvancement(
        currentLevel: Level,
        levelsCount: number
    ) {
        logger(debug)

        if (viewer.value === undefined) throw 'Viewer not initialized'
        if (viewer.value.activeCourse === undefined)
            throw 'Active course undefined'
        if (viewer.value.courses === undefined) throw 'Viewer courses undefined'

        const currentAccessLevel =
            viewer.value.courses[viewer.value.activeCourse]
        const isNotAdvanceable = currentAccessLevel < 1

        // Current level access must coincide with the current level number,
        // ensuring that the unlocked level is always the next one.
        if (currentAccessLevel !== currentLevel.num) return

        if (isNotAdvanceable) return

        const viewerDoc = await firestore
            .collection('users')
            .doc(viewer.value.id)
            .get()
            .then(docData<User>)

        const isActiveCourseStale =
            viewerDoc.activeCourse === undefined ||
            viewerDoc.courses?.[viewerDoc.activeCourse] === undefined

        if (isActiveCourseStale) return

        let newAccessLevel = currentAccessLevel + 1

        if (newAccessLevel >= levelsCount) {
            newAccessLevel = 0 // Unlock all levels (0 = all) if the newAccessLevel is the last one.
        }

        await firestore
            .collection('users')
            .doc(viewer.value.id)
            .update({
                [`courses.${viewer.value.activeCourse}`]: newAccessLevel,
            })

        viewer.value.courses[viewer.value.activeCourse] = newAccessLevel
    }

    async function updateViewerEntity(
        entityName: keyof UserProperties & ('agency' | 'classrooms' | 'school'),
        entityId: string
    ) {
        if (viewer.value === undefined) return

        const teacherDoc = firestore.collection('users').doc(viewer.value.id)
        const updateData = {
            [`properties.${entityName}`]:
                entityName === 'classrooms' ? arrayUnion(entityId) : entityId,
        }

        await teacherDoc.update(updateData)

        // Update viewer's properties locally
        if (entityName === 'classrooms') {
            viewer.value.properties = {
                ...viewer.value.properties,
                classrooms: viewer.value.properties?.classrooms
                    ? [...viewer.value.properties.classrooms, entityId]
                    : [entityId],
            }
        } else {
            viewer.value.properties = {
                ...viewer.value.properties,
                [entityName]: entityId,
            }
        }
    }

    function batchUpdateDisplayName(batch: FirestoreWriteBatch) {
        logger(debug)
        if (viewer.value === undefined) return
        if (viewer.value.displayName === undefined) return

        const displayName =
            viewer.value.displayName === ''
                ? deleteField()
                : viewer.value.displayName

        batch.update(firestore.collection('users').doc(viewer.value.id), {
            displayName,
        })
    }

    async function updateViewerSettings(updatedSettings: {
        displayName?: string
        displayMode?: DisplayMode
        options?: UserOption[]
    }) {
        logger(debug, updatedSettings)
        if (viewer.value === undefined) return Promise.resolve()

        const { displayName, displayMode, options } = updatedSettings

        const batch = firestore.batch()

        if (displayMode) viewer.value.displayMode = displayMode
        if (displayName !== undefined) {
            const { batchUpdateChatsDisplayName } = useChats()
            viewer.value.displayName =
                displayName.length > 0 ? displayName : undefined

            batchUpdateDisplayName(batch)
            await batchUpdateChatsDisplayName(
                displayName || viewer.value.name,
                viewer.value.id,
                batch
            )
        }
        if (options) viewer.value.options = options

        batch.update(
            firestore.collection('users').doc(viewer.value.id),
            updatedSettings
        )

        return batch.commit()
    }

    function setInitialLoginTime() {
        logger(debug)
        if (viewer.value === undefined) return Promise.resolve()

        return firestore.collection('users').doc(viewer.value.id).update({
            initialLoginAt: serverTimestamp(),
        })
    }

    async function updateLoginTime() {
        logger(debug)
        if (viewer.value === undefined) return Promise.resolve()

        let viewerTimeZone = ''
        // Intl.DateTimeFormat... may return undefined OR a default timezone if none found
        switch (Intl.DateTimeFormat().resolvedOptions().timeZone) {
            case 'America/Detroit':
                viewerTimeZone = 'eastern'
                break
            case 'America/Chicago':
                viewerTimeZone = 'central'
                break
            case 'America/Denver':
                viewerTimeZone = 'mountain'
                break
            case 'America/Puerto_Rico':
                viewerTimeZone = 'atlantic'
                break
            default:
                viewerTimeZone = 'pacific'
                break
        }

        if (viewer.value.initialLoginAt === undefined) setInitialLoginTime()

        return firestore
            .collection('users')
            .doc(viewer.value.id)
            .update({ loginAt: serverTimestamp(), timezone: viewerTimeZone })
    }

    async function updateActiveTime() {
        logger(debug)
        if (viewer.value === undefined) return Promise.resolve()

        return firestore
            .collection('users')
            .doc(viewer.value.id)
            .update({ activeAt: serverTimestamp() })
    }

    async function updateViewerLockedPage(lockedPage: {
        courseId: string
        levelId: string
        pageId: string
    }) {
        logger(debug, lockedPage)
        if (viewer.value === undefined) return Promise.resolve()

        await firestore.collection('users').doc(viewer.value.id).update({
            lockedPage,
        })

        viewer.value.lockedPage = {
            ...lockedPage,
        }
    }

    async function updateViewerMoodAnchors(
        highAnchor?: string,
        lowAnchor?: string
    ) {
        logger(debug)
        if (viewer.value === undefined) return Promise.resolve()

        await firestore
            .collection('users')
            .doc(viewer.value.id)
            .update({
                ...(highAnchor && { 'properties.moodAnchorHigh': highAnchor }),
                ...(lowAnchor && { 'properties.moodAnchorLow': lowAnchor }),
            })

        viewer.value.properties = {
            ...viewer.value.properties,
            ...(highAnchor && { moodAnchorHigh: highAnchor }),
            ...(lowAnchor && { moodAnchorLow: lowAnchor }),
        }
    }

    async function updateReachableNextStep(nextStepId?: string) {
        logger(debug, nextStepId)
        if (viewer.value === undefined) throw 'Viewer not initialized'

        if (nextStepId) {
            viewer.value = {
                ...viewer.value,
                properties: {
                    ...viewer.value.properties,
                    currentStep: nextStepId,
                },
            }
        } else {
            delete viewer.value.properties?.currentStep
        }

        return firestore
            .collection('users')
            .doc(viewer.value.id)
            .update({ 'properties.currentStep': nextStepId ?? deleteField() })
    }

    async function updateSeenInteractives(interactiveId: PageFeature) {
        logger(debug, interactiveId)
        if (viewer.value === undefined) throw 'Viewer not initialized'

        const seenInteractives = [
            ...(viewer.value?.properties?.seenInteractives ?? []),
            interactiveId,
        ]

        viewer.value = {
            ...viewer.value,
            properties: {
                ...viewer.value.properties,
                seenInteractives,
            },
        }

        return firestore
            .collection('users')
            .doc(viewer.value.id)
            .update({ 'properties.seenInteractives': seenInteractives })
    }

    async function updateSibTimeMoments(
        sibtime: SibTimeMoment,
        fieldsToUpdate: (keyof SibTimeMoment)[]
    ) {
        logger(debug, sibtime)
        if (viewer.value === undefined) return Promise.resolve()

        const update: {
            [key in `sibTimeMoment.${keyof SibTimeMoment}`]?:
                | SibTimeMoment[keyof SibTimeMoment]
                | FirestoreFieldValue
        } = {}

        fieldsToUpdate?.forEach((field) => {
            if (sibtime[field] === undefined) {
                update[`sibTimeMoment.${field}`] = deleteField()
            } else {
                update[`sibTimeMoment.${field}`] = sibtime[field]
            }
        })

        await firestore.collection('users').doc(viewer.value.id).update(update)

        viewer.value.sibTimeMoment = {
            ...viewer.value.sibTimeMoment,
            ...sibtime,
        }
    }

    async function updateViewerPals(
        childAverage?: number,
        childGoal?: number,
        selfAverage?: number,
        selfGoal?: number
    ) {
        logger(debug, childAverage, childGoal, selfAverage, selfGoal)
        if (viewer.value === undefined) return Promise.resolve()

        await firestore
            .collection('users')
            .doc(viewer.value.id)
            .update({
                ...(childAverage && {
                    'properties.palsChildAverage': childAverage,
                }),
                ...(childGoal && { 'properties.palsChildGoal': childGoal }),
                ...(selfAverage && {
                    'properties.palsSelfAverage': selfAverage,
                }),
                ...(selfGoal && { 'properties.palsSelfGoal': selfGoal }),
            })

        viewer.value.properties = {
            ...viewer.value.properties,
            ...(childAverage && { palsChildAverage: childAverage }),
            ...(childGoal && { palsChildGoal: childGoal }),
            ...(selfAverage && { palsSelfAverage: selfAverage }),
            ...(selfGoal && { palsSelfGoal: selfGoal }),
        }
    }

    async function updateViewerBrushRecord(newRecordTime: number) {
        logger(debug)
        if (viewer.value === undefined) return Promise.resolve()

        viewer.value.properties = {
            ...viewer.value.properties,
            recordBrushTime: newRecordTime,
        }

        return firestore.collection('users').doc(viewer.value.id).update({
            'properties.recordBrushTime': newRecordTime,
        })
    }

    async function updateViewerStatus(newStatus: UserStatus) {
        logger(debug)
        if (viewer.value === undefined) return Promise.resolve()

        viewer.value.status = newStatus

        return firestore.collection('users').doc(viewer.value.id).update({
            status: newStatus,
        })
    }

    async function updateOnTheMoveAudioIndex(index: number) {
        logger(debug)
        if (viewer.value === undefined) return Promise.resolve()

        viewer.value.properties = {
            ...viewer.value.properties,
            onTheMoveAudioIndex: index,
        }

        return firestore.collection('users').doc(viewer.value.id).update({
            'properties.onTheMoveAudioIndex': index,
        })
    }

    const throttledUpdateActiveTime = useThrottleFn(() => {
        updateActiveTime()
    }, 60000) // Per minute

    async function activateViewerDevice(token: Token) {
        logger(debug, token)

        if (isOnWeb.value) return

        return firestore
            .collection('users')
            .doc(viewer.value?.id)
            .collection('devices')
            .doc(viewerDeviceId.value?.identifier)
            .set({
                token: token.value,
                active: true,
            })
    }

    async function deactivateViewerDevice() {
        logger(debug)

        if (isOnWeb.value) return

        return firestore
            .collection('users')
            .doc(viewer.value?.id)
            .collection('devices')
            .doc(viewerDeviceId.value?.identifier)
            .update({
                active: false,
            })
    }
    function removeAllListeners() {
        logger(debug)
        if (!unsubscribe.value) return
        unsubscribe.value()
        unsubscribe.value = undefined
    }

    async function deinitViewer() {
        logger(debug)
        await deactivateViewerDevice()
        viewer.value = undefined
        viewerDeviceInfo.value = undefined
        viewerDeviceId.value = undefined
        playedRequiredMedia.value = new Set<string>()
        removeAllListeners()
    }

    return {
        authUser: readonly(authUser),
        viewer: readonly(viewer),
        viewerDeviceInfo: readonly(viewerDeviceInfo),
        grantableRolesByViewer,
        hasAutoplayEnabled,
        isAdmin,
        isEditor,
        isGuide,
        isManager,
        isParticipant,
        isOnAndroid,
        isOnIos,
        isOnWeb,
        isActive,
        isCompleted,
        isInactive,
        isNew,
        isStaff,
        isWithdrawn,
        viewersCourseIds,

        activateViewerDevice,
        deinitViewer,
        initViewer,
        isRequiredMediaPlayed,
        setRequiredMediaPlayed,
        throttledUpdateActiveTime,
        updateLoginTime,
        updateActiveCourse,
        updateCourseAdvancement,
        updateViewerEntity,
        updateViewerLockedPage,
        updateOnTheMoveAudioIndex,
        updateReachableNextStep,
        updateSeenInteractives,
        updateSibTimeMoments,
        updateViewerBrushRecord,
        updateViewerMoodAnchors,
        updateViewerPals,
        updateViewerSettings,
        updateViewerStatus,
    }
}
