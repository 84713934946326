import { differenceInDays } from 'date-fns'
import { computed, readonly, Ref, ref } from 'vue'

import {
    firestore,
    serverTimestamp,
    FirestoreTimestamp,
} from '@/firebase-config'

import useCourse from '@/composables/global/use-courses'
import useViewer from '@/composables/global/use-viewer'

import { logger } from '@/utils/debug'
import { getCollectionDocs } from '@/utils/firestore'

import { User } from '@/models/user'
import {
    ColumbiaQuestionResponse,
    ColumbiaScreeningFormName,
    ColumbiaSelfReport,
    RiskLevel,
} from '@/models/users/columbia-self-report'

const debug = false

export type ColumbiaFormData = {
    version?: ColumbiaScreeningFormName
    riskLevel?: RiskLevel
    q1?: ColumbiaQuestionResponse
    q2?: ColumbiaQuestionResponse
    q3?: ColumbiaQuestionResponse
    q4?: ColumbiaQuestionResponse
    q5?: ColumbiaQuestionResponse
    q6?: ColumbiaQuestionResponse
    q6b?: ColumbiaQuestionResponse
}

export type UserColumbiaReport = {
    researchId?: string
    displayName?: string
    name: string
    createdAt: FirestoreTimestamp
    byGuide?: boolean
    riskLevel: RiskLevel
    version: ColumbiaScreeningFormName
    q1: ColumbiaQuestionResponse
    q2: ColumbiaQuestionResponse
    q3?: ColumbiaQuestionResponse
    q4?: ColumbiaQuestionResponse
    q5?: ColumbiaQuestionResponse
    q6: ColumbiaQuestionResponse
    q6b?: ColumbiaQuestionResponse
}

const columbiaSelfReports: Ref<ColumbiaSelfReport[]> = ref([])

// Viewers with the roles admin, guide, or manager will have this populated
// with each participant id they are assigned to see who has an unviewed columbia report
const usersWithUnviewedColumbiaSelfReports: Ref<string[]> = ref([])

export default function () {
    const { isAdmin, isEditor, isGuide, isManager, isParticipant, viewer } =
        useViewer()
    const { isOptionSet } = useCourse()

    const lastColumbiaResponseDate = computed(() => {
        const [lastResponse] = columbiaSelfReports.value.slice(-1)
        return lastResponse?.createdAt.toDate()
    })

    const columbiaSelfReportVersion = computed(() => {
        if (lastColumbiaResponseDate.value === undefined) {
            return 'Self Report Recent'
        }
        const currentDate = new Date()
        const interval = differenceInDays(
            lastColumbiaResponseDate.value,
            currentDate
        )
        // 30 days or more since last colombia form
        if (interval >= 30) {
            return 'Self Report Recent'
        } else {
            return 'Self Report Since Last Visit'
        }
    })

    async function fetchColumbiaSelfReports(userId?: string) {
        logger(debug, userId)

        const userDocId = userId ?? viewer.value?.id

        if (!userDocId) {
            return []
        }

        const columbiaSelfReportsSnapshot = await firestore
            .collection('users')
            .doc(userDocId)
            .collection('columbia-self-reports')
            .orderBy('createdAt')
            .get()

        if (columbiaSelfReportsSnapshot.empty) {
            return []
        } else {
            return getCollectionDocs<ColumbiaSelfReport>(
                columbiaSelfReportsSnapshot
            )
        }
    }

    async function fetchUsersColumbiaSelfReports(userList: User[]) {
        logger(debug)

        let allUsersColumbiaReports: UserColumbiaReport[] = []
        await Promise.all(
            userList.map(async (user) => {
                const columbiaReportsSnapshot = await firestore
                    .collection('users')
                    .doc(user.id)
                    .collection('columbia-self-reports')
                    .get()
                if (columbiaReportsSnapshot.empty) return
                const columbiaReports = getCollectionDocs<ColumbiaSelfReport>(
                    columbiaReportsSnapshot
                )

                const columbiaReportsWithResearchId = columbiaReports.map(
                    (report) => ({
                        createdAt: report.createdAt,
                        byGuide: report.byGuide,
                        riskLevel: report.riskLevel,
                        version: report.version,
                        q1: report.q1,
                        q2: report.q2,
                        q3: report.q3,
                        q4: report.q4,
                        q5: report.q5,
                        q6: report.q6,
                        q6b: report.q6b,
                        researchId: user.researchId,
                        displayName: user.displayName,
                        name: user.name,
                    })
                )
                allUsersColumbiaReports = allUsersColumbiaReports.concat(
                    columbiaReportsWithResearchId
                )
            })
        )
        return allUsersColumbiaReports
    }

    async function fetchUsersWithUnviewedColumbiaSelfReports() {
        logger(debug)
        if (viewer.value === undefined) throw 'Viewer is not defined'
        const isJustEditor =
            isEditor.value &&
            !(isAdmin.value || isManager.value || isGuide.value)
        if (isParticipant.value || isJustEditor) {
            usersWithUnviewedColumbiaSelfReports.value = []
            return
        }

        const unviewedColumbiaReportsSnapshot = await firestore
            .collectionGroup('columbia-self-reports')
            .where('hasNotBeenViewedBy', 'array-contains', viewer.value?.id)
            .get()

        if (unviewedColumbiaReportsSnapshot.empty) {
            usersWithUnviewedColumbiaSelfReports.value = []
            return
        } else {
            usersWithUnviewedColumbiaSelfReports.value =
                unviewedColumbiaReportsSnapshot.docs.flatMap((doc) => {
                    if (doc.ref.parent.parent !== null) {
                        return doc.ref.parent.parent.id
                    }
                    return []
                })
        }
    }

    async function addColumbiaSelfReport(
        columbiaSelfReportData: ColumbiaFormData,
        userId?: string
    ) {
        logger(debug, columbiaSelfReportData)
        const userDocId = userId ?? viewer.value?.id
        const byGuide = userId !== undefined
        if (userDocId === undefined) throw 'User ID is undefined'

        return firestore
            .collection('users')
            .doc(userDocId)
            .collection('columbia-self-reports')
            .add({
                ...columbiaSelfReportData,
                byGuide: byGuide,
                createdAt: serverTimestamp(),
            })
    }

    async function loadColumbiaSelfReports() {
        logger(debug)
        if (!viewer.value?.id) {
            columbiaSelfReports.value = []
            usersWithUnviewedColumbiaSelfReports.value = []
            return
        }
        await fetchUsersWithUnviewedColumbiaSelfReports()
        if (!isOptionSet('phq9')) {
            columbiaSelfReports.value = []
            return
        }
        columbiaSelfReports.value = await fetchColumbiaSelfReports()
    }

    async function updateColumbiaSelfReportViewer(
        reportId: string,
        userId: string,
        hasNotBeenViewedBy: string[]
    ) {
        logger(debug, reportId, userId, hasNotBeenViewedBy)
        if (viewer.value === undefined) throw 'Viewer not initialized'

        const viewerIndex = hasNotBeenViewedBy.indexOf(viewer.value.id)
        if (viewerIndex === -1) return
        // Remove viewer from the array of those who havent seen the report
        hasNotBeenViewedBy.splice(viewerIndex, 1)

        await firestore
            .collection('users')
            .doc(userId)
            .collection('columbia-self-reports')
            .doc(reportId)
            .update({
                hasNotBeenViewedBy: hasNotBeenViewedBy,
            })

        await fetchUsersWithUnviewedColumbiaSelfReports()
    }

    function deinitColumbiaSelfReport() {
        logger(debug)
        columbiaSelfReports.value = []
    }

    return {
        usersWithUnviewedColumbiaSelfReports: readonly(
            usersWithUnviewedColumbiaSelfReports
        ),

        columbiaSelfReportVersion,

        addColumbiaSelfReport,
        deinitColumbiaSelfReport,
        fetchColumbiaSelfReports,
        fetchUsersColumbiaSelfReports,
        loadColumbiaSelfReports,
        updateColumbiaSelfReportViewer,
    }
}
