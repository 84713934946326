import { readonly, Ref, ref } from 'vue'

import {
    firestore,
    FirebaseDocumentSnapshot,
    serverTimestamp,
} from '@/firebase-config'

import useCourses from '@/composables/global/use-courses'
import useLevelPage from '@/composables/global/use-level-page'
import useLevels from '@/composables/global/use-levels'
import useViewer from '@/composables/global/use-viewer'

import { updateLogSummaries } from '@/utils/callable-cloud-functions'
import { logger } from '@/utils/debug'
import { docData } from '@/utils/firestore'

import { Level } from '@/models/courses/level'
import { Page } from '@/models/courses/levels/page'
import { LogSummary } from '@/models/users/log-summary'
import { Progress } from '@/models/users/progress'

const debug = false

export type LevelData = {
    id: string
    num: number
    title: string
    furthestPage: Page | undefined
    totalPageCount: number
    timeSpent?: number
}

/**
 * Expose ref for current progress loaded and helper functions
 * First loaded on app launch with viewer progress
 */

const progress: Ref<Progress | undefined> = ref()
const unsubFromProgress: Ref<(() => void) | undefined> = ref()

export default function () {
    const { activeCourse } = useCourses()
    const { viewer, updateCourseAdvancement } = useViewer()

    function removeProgressListener() {
        logger(debug)
        if (unsubFromProgress.value !== undefined) {
            unsubFromProgress.value()
            unsubFromProgress.value = undefined
        }
    }

    function handleProgressSnapshot(
        progressSnapshot: FirebaseDocumentSnapshot
    ) {
        logger(debug)
        const retrievedProgress = docData<Progress>(progressSnapshot)
        if (retrievedProgress.id !== progress.value?.id) return
        if (retrievedProgress.levelsProgress) {
            progress.value = {
                ...progress.value,
                levelsProgress: {
                    ...progress.value.levelsProgress,
                    ...retrievedProgress.levelsProgress,
                },
                progressAt: retrievedProgress.progressAt,
            }
        }
    }

    function getProgressDoc(userId: string) {
        logger(debug, userId)
        if (activeCourse.value === undefined) throw 'Missing active course'

        const progressDoc = firestore
            .collection('users')
            .doc(userId)
            .collection('progress')
            .doc(activeCourse.value.id)

        return progressDoc
    }

    async function getUserProgress(userId: string) {
        logger(debug, userId)

        const userProgress = await getProgressDoc(userId)
            .get()
            .then(docData<Progress>)
        return userProgress
    }

    async function loadViewerProgress(): Promise<void> {
        logger(debug)

        removeProgressListener()

        if (viewer.value === undefined) throw 'Viewer not initialized'

        const progressDoc = getProgressDoc(viewer.value.id)
        const progressSnapshot = await progressDoc.get()
        progress.value = docData<Progress>(progressSnapshot)

        // Subscribe to viewer progress
        unsubFromProgress.value = progressDoc.onSnapshot(
            (progressSnapshot: FirebaseDocumentSnapshot) =>
                handleProgressSnapshot(progressSnapshot)
        )
    }

    function assembleProgressData(
        levelId: string,
        pageId: string,
        isNewProgress: boolean
    ) {
        logger(debug, levelId, pageId)
        return {
            levelsProgress: {
                [levelId]: {
                    latestPage: pageId,
                    latestAt: serverTimestamp(),
                    ...(isNewProgress && {
                        furthestPage: pageId,
                        furthestAt: serverTimestamp(),
                    }),
                },
            },
            ...(isNewProgress && { progressAt: serverTimestamp() }),
        }
    }

    function writeProgress(progressData: object) {
        logger(debug, progressData)
        if (viewer.value === undefined) throw 'Viewer not initialized'
        if (progress.value === undefined) throw 'Progress not initiated'

        return firestore
            .collection('users')
            .doc(viewer.value.id)
            .collection('progress')
            .doc(progress.value.id)
            .set(progressData, { merge: true })
    }

    async function updateProgress(levelId: string, pageId: string) {
        logger(debug, levelId, pageId)

        const { page, fetchFinalPage, fetchPage } = useLevelPage(levelId)

        const finalPage = await fetchFinalPage()

        const levelProgress = progress.value?.levelsProgress?.[levelId]
        const currentPage = page.value

        let isNewProgress = true

        if (levelProgress) {
            // Verify isNewProgress
            const furthestPageId = levelProgress.furthestPage
            const furthestPage = await fetchPage(furthestPageId)

            if (currentPage !== undefined && furthestPage !== undefined) {
                isNewProgress = currentPage.num > furthestPage.num
            }
        }

        const isFinalPage = currentPage?.num === finalPage?.num
        const isAutoAdvanceMode =
            activeCourse.value?.properties?.['advanceMode'] === 'auto'

        const { levels } = useLevels()
        const currentLevel = levels.value.find((l) => l.id === levelId) as Level

        if (isNewProgress && isFinalPage && isAutoAdvanceMode && currentLevel) {
            // Finished auto advance level for first time
            await updateCourseAdvancement(currentLevel, levels.value.length)
        }

        const progressData = assembleProgressData(
            levelId,
            pageId,
            isNewProgress
        )
        await writeProgress(progressData)
    }

    async function fetchLogSummary(userId: string): Promise<LogSummary> {
        logger(debug)

        if (viewer.value === undefined) throw 'Viewer not initialized'

        // Update log summaries for the user
        await updateLogSummaries({
            callerId: viewer.value.id,
            userIds: [userId],
        })

        return firestore
            .collection('users')
            .doc(userId)
            .collection('log-summary')
            .doc('log-summary')
            .get()
            .then(docData<LogSummary>)
    }

    function loadTimeSpent(levelId: string, log: LogSummary) {
        const courseId = activeCourse.value?.id
        if (!courseId) throw new Error('Course ID not found')

        return log.timeInCourses[courseId]?.timeInLevels[levelId]?.total ?? 0
    }

    async function assembleLevelData(
        levelData: {
            id: string
            title: string
            num: number
        },
        progress: Progress
    ) {
        logger(debug, levelData)
        const { id, title, num } = levelData
        const levelProgress = progress?.levelsProgress?.[id]
        const { fetchPage, getTotalRootPageCount } = useLevelPage(id)
        const furthestPage =
            levelProgress === undefined
                ? undefined
                : await fetchPage(levelProgress.furthestPage)
        const totalPageCount = await getTotalRootPageCount()

        return { id, num, title, furthestPage, totalPageCount }
    }

    async function fetchTabulatedProgress(userProgress: Progress) {
        logger(debug, userProgress)

        const { levels } = useLevels()

        const tabulatedProgress: LevelData[] = await Promise.all(
            levels.value?.map((level) => assembleLevelData(level, userProgress))
        )

        return tabulatedProgress
    }

    function deinitProgress() {
        logger(debug)
        removeProgressListener()
        progress.value = undefined
    }

    return {
        // State
        progress: readonly(progress),

        // Functions
        deinitProgress,
        fetchTabulatedProgress,
        fetchLogSummary,
        loadTimeSpent,
        getUserProgress,
        loadViewerProgress,
        updateProgress,
    }
}
