import { StatusBar, Style } from '@capacitor/status-bar'
import { Ref, ref, readonly } from 'vue'

import { setThemeToDocumentCss } from '@/composables/global/use-themes'
import useViewer from '@/composables/global/use-viewer'

import { logger } from '@/utils/debug'
import {
    getDisplayModePref,
    setDisplayModePref,
} from '@/utils/user-preferences'

import { DisplayMode, DisplayTheme } from '@/models/user'

const debug = false

const currentDisplayTheme: Ref<DisplayTheme> = ref('light')
const currentDisplayMode: Ref<DisplayMode> = ref('auto')

const systemPrefersDark = window.matchMedia('(prefers-color-scheme: dark)')

const { isOnAndroid, isOnIos } = useViewer()

async function setStatusBarStyle(style: Style) {
    logger(debug, style)

    if (isOnAndroid.value || isOnIos.value) {
        StatusBar.setStyle({ style })
    }
}

function getDisplayThemeForMode(mode: DisplayMode) {
    logger(debug, mode)
    if (mode === 'auto') return systemPrefersDark.matches ? 'dark' : 'light'
    else return mode
}

async function setInitDisplayTheme() {
    logger(debug)
    const storedDisplayMode = await getDisplayModePref()
    currentDisplayTheme.value = getDisplayThemeForMode(storedDisplayMode)
    setThemeToDocumentCss({ mode: currentDisplayTheme.value })
    document.body.classList.toggle('dark', currentDisplayTheme.value === 'dark')
}

async function setDisplayMode(mode: DisplayMode) {
    logger(debug, mode)
    currentDisplayMode.value = mode
    currentDisplayTheme.value = getDisplayThemeForMode(mode)
    setStatusBarStyle(currentDisplayTheme.value.toUpperCase() as Style)
    setThemeToDocumentCss({ mode: currentDisplayTheme.value })
    document.body.classList.toggle('dark', currentDisplayTheme.value === 'dark')
    await setDisplayModePref(mode)
}

function systemDisplaySettingChanged() {
    logger(debug)
    if (currentDisplayMode.value === 'auto') {
        setDisplayMode('auto')
    }
}

// Listen for changes to the prefers-color-scheme media query
systemPrefersDark.addEventListener('change', systemDisplaySettingChanged)

export default function () {
    return {
        displayMode: readonly(currentDisplayMode),
        displayTheme: readonly(currentDisplayTheme),
        getDisplayThemeForMode,
        setDisplayMode,
        setInitDisplayTheme,
    }
}
