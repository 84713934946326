export type Moment = {
    id: string
    text: string
    textEs: string
    module: number
    positiveFeedbackEn: string
    positiveFeedbackEs: string
    negativeFeedbackEn: string
    negativeFeedbackEs: string
}

export const moments: Moment[] = [
    {
        id: '1',
        text: 'How do you feel today about connecting with your child through play?',
        textEs: '¿Cómo te sientes hoy acerca de conectar con tu hijo/a a través del juego?',
        module: 1,
        positiveFeedbackEn: 'Moments 1 A English.mp4',
        positiveFeedbackEs: 'Moments 1 A Spanish.mp4',
        negativeFeedbackEn: 'Moments 1 B English.mp4',
        negativeFeedbackEs: 'Moments 1 B Spanish.mp4',
    },
    {
        id: '2',
        text: 'How do you feel today about connecting with your child through talking and listening?',
        textEs: '¿Cómo te sientes hoy acerca de conectar con tu hijo/a a través del habla y la escucha?',
        module: 2,
        positiveFeedbackEn: 'Moments 2 A English.mp4',
        positiveFeedbackEs: 'Moments 2 A Spanish.mp4',
        negativeFeedbackEn: 'Moments 2 B English.mp4',
        negativeFeedbackEs: 'Moments 2 B Spanish.mp4',
    },
    {
        id: '3',
        text: 'How do you feel today about connecting with your child through learning?',
        textEs: '¿Cómo te sientes hoy acerca de conectar con tu hijo/a a través del aprendizaje?',
        module: 3,
        positiveFeedbackEn: 'Moments 3 A English.mp4',
        positiveFeedbackEs: 'Moments 3 A Spanish.mp4',
        negativeFeedbackEn: 'Moments 3 B English.mp4',
        negativeFeedbackEs: 'Moments 3 B Spanish.mp4',
    },
    {
        id: '4',
        text: 'How do you feel today about connecting with your child through cooperation?',
        textEs: '¿Cómo te sientes hoy acerca de conectar con tu hijo/a a través de la cooperación?',
        module: 4,
        positiveFeedbackEn: 'Moments 4 A English.mp4',
        positiveFeedbackEs: 'Moments 4 A Spanish.mp4',
        negativeFeedbackEn: 'Moments 4 B English.mp4',
        negativeFeedbackEs: 'Moments 4 B Spanish.mp4',
    },
    {
        id: '5',
        text: 'How do you feel today about connecting with your child through sharing affection?',
        textEs: '¿Cómo te sientes hoy acerca de conectar con tu hijo/a a través del cariño?',
        module: 5,
        positiveFeedbackEn: 'Moments 5 A English.mp4',
        positiveFeedbackEs: 'Moments 5 A Spanish.mp4',
        negativeFeedbackEn: 'Moments 5 B English.mp4',
        negativeFeedbackEs: 'Moments 5 B Spanish.mp4',
    },
    {
        id: '6',
        text: 'How do you feel today about helping your child when they want to feel special, too?',
        textEs: '¿Cómo te sientes hoy acerca de ayudar a tu hijo/a cuando quiere sentirse especial también?',
        module: 6,
        positiveFeedbackEn: 'Moments 6 A English.mp4',
        positiveFeedbackEs: 'Moments 6 A Spanish.mp4',
        negativeFeedbackEn: 'Moments 6 B English.mp4',
        negativeFeedbackEs: 'Moments 6 B Spanish.mp4',
    },
    {
        id: '7',
        text: 'How do you feel today about helping your child when they feel disappointed?',
        textEs: '¿Cómo te sientes hoy acerca de ayudar a tu hijo/a cuando se siente decepcionado/a?',
        module: 7,
        positiveFeedbackEn: 'Moments 7 A English.mp4',
        positiveFeedbackEs: 'Moments 7 A Spanish.mp4',
        negativeFeedbackEn: 'Moments 7 B English.mp4',
        negativeFeedbackEs: 'Moments 7 B Spanish.mp4',
    },
    {
        id: '8',
        text: 'How do you feel today about helping your child when they feel confused?',
        textEs: '¿Cómo te sientes hoy acerca de ayudar a tu hijo/hija cuando se siente confundido/a?',
        module: 8,
        positiveFeedbackEn: 'Moments 8 A English.mp4',
        positiveFeedbackEs: 'Moments 8 A Spanish.mp4',
        negativeFeedbackEn: 'Moments 8 B English.mp4',
        negativeFeedbackEs: 'Moments 8 B Spanish.mp4',
    },
    {
        id: '9',
        text: 'How do you feel today about helping your child when they don’t get along with their sibling?',
        textEs: '¿Cómo te sientes hoy acerca de ayudar a tu hijo/a cuando no se lleva bien con su hermano/a?',
        module: 9,
        positiveFeedbackEn: 'Moments 9 A English.mp4',
        positiveFeedbackEs: 'Moments 9 A Spanish.mp4',
        negativeFeedbackEn: 'Moments 9 B English.mp4',
        negativeFeedbackEs: 'Moments 9 B Spanish.mp4',
    },
    {
        id: '10',
        text: 'How do you feel today about helping your young child when they want more from their sibling? ',
        textEs: '¿Cómo te sientes hoy acerca de ayudar a tu hijo/a cuando quiere hacer más cosas de hermanos? ',
        module: 10,
        positiveFeedbackEn: 'Moments 10 A English.mp4',
        positiveFeedbackEs: 'Moments 10 A Spanish.mp4',
        negativeFeedbackEn: 'Moments 10 B English.mp4',
        negativeFeedbackEs: 'Moments 10 B Spanish.mp4',
    },
]
