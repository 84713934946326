import { Preferences } from '@capacitor/preferences'

import appConfig from '@/app-config'

import { logger } from '@/utils/debug'

import { AdminSettings } from '@/models/admin-settings'
import { DisplayMode } from '@/models/user'

const debug = false

const ADMIN_SETTINGS_KEY = `${appConfig.projectId}-admin-settings`
const CLOSED_CAPTIONS_KEY = `${appConfig.projectId}-closed-captions`
const DISPLAY_MODE_KEY = `${appConfig.projectId}-display-mode`

export type ClosedCaptionsPref = 'showing' | 'disabled' | 'hidden'

export async function getAdminSettingsPref() {
    logger(debug)
    const result = await Preferences.get({ key: ADMIN_SETTINGS_KEY })
    if (result.value === null) return undefined
    return JSON.parse(result.value) as AdminSettings
}

export async function setAdminSettingsPref(
    settings: AdminSettings | undefined
) {
    logger(debug, settings)
    return Preferences.set({
        key: ADMIN_SETTINGS_KEY,
        value: JSON.stringify(settings),
    })
}

export async function getClosedCaptionsPref(): Promise<ClosedCaptionsPref> {
    logger(debug)
    const result = await Preferences.get({ key: CLOSED_CAPTIONS_KEY })
    if (result.value === null) return 'disabled'
    return result.value as ClosedCaptionsPref
}

export async function setClosedCaptionsPref(pref: ClosedCaptionsPref) {
    logger(debug, pref)
    return Preferences.set({ key: CLOSED_CAPTIONS_KEY, value: pref })
}

export async function getDisplayModePref() {
    logger(debug)
    const result = await Preferences.get({ key: DISPLAY_MODE_KEY })
    if (result.value === null) return 'auto'
    return result.value as DisplayMode
}

export async function setDisplayModePref(mode: DisplayMode) {
    logger(debug, mode)
    return Preferences.set({ key: DISPLAY_MODE_KEY, value: mode })
}
