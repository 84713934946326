import { App } from '@capacitor/app'
import { PluginListenerHandle } from '@capacitor/core'
import { modalController } from '@ionic/vue'
import { v4 as generateUniqueId } from 'uuid'
import { watch, DeepReadonly, ref } from 'vue'

import { storage } from '@/firebase-config'

import SibtimeMomentsNotification from '@/components/SibtimeMomentsNotification.vue'

import useProgress from '@/composables/global/use-progress'
import useViewer from '@/composables/global/use-viewer'

import { logger } from '@/utils/debug'

import { moments } from '@/data/sibtime-moments'

import { User } from '@/models/user'

const debug = false
const appStateChangeListener = ref<PluginListenerHandle>()

// Unique ID for this instance (tab or device)
const instanceId = generateUniqueId()

export default function () {
    const { viewer, updateSibTimeMoments } = useViewer()
    const { getUserProgress, fetchTabulatedProgress } = useProgress()

    function removeAllListeners() {
        logger(debug)
        if (appStateChangeListener.value) {
            appStateChangeListener.value.remove()
            appStateChangeListener.value = undefined
        }
    }

    async function initSibtimeMoments() {
        logger(debug)

        // Listen for changes from background to foreground
        appStateChangeListener.value = await App.addListener(
            'appStateChange',
            handleAppStateChange
        )
        //Initial check for changes in the app state
        const appState = await App.getState()
        await handleAppStateChange({ isActive: appState.isActive })
    }

    const isAppActive = ref(false)
    const isModalCreated = ref(false)

    async function handleAppStateChange(state: { isActive: boolean }) {
        logger(debug)
        if (viewer.value && state.isActive) {
            isAppActive.value = true
            // If the instance (tab or device)  becomes active, check if there's a pending modal to be shown

            handleUserSnapshot(viewer.value)
        } else {
            isAppActive.value = false
        }
    }

    async function handleUserSnapshot(user: DeepReadonly<User>) {
        logger(debug)

        if (
            user.sibTimeMoment &&
            user.sibTimeMoment.mustTrigger &&
            isAppActive.value
        ) {
            if (!user.sibTimeMoment.activeInstanceId && !isModalCreated.value) {
                isModalCreated.value = true
                const sibTimeMoment = await openSibTimeMomentModal()

                sibTimeMoment?.onDidDismiss().then(async () => {
                    // Only update the activeInstanceId if the modal (where it was answered) was closed by the viewer
                    if (
                        viewer.value?.sibTimeMoment?.activeInstanceId ===
                        instanceId
                    ) {
                        updateSibTimeMoments(
                            {
                                activeInstanceId: undefined,
                            },
                            ['activeInstanceId']
                        )
                    }
                    isModalCreated.value = false
                })
            }
        }

        // Case where the sibTimeMoment was already triggered and answered in another tab or device,
        // close all modals except the one that was answered to watch the video
        else if (
            isAppActive.value &&
            user.sibTimeMoment &&
            !user.sibTimeMoment.mustTrigger &&
            user.sibTimeMoment?.activeInstanceId &&
            user.sibTimeMoment?.activeInstanceId !== instanceId
        ) {
            closeModalIfOpen()
        }
        // Case where the sibTimeMoment was already triggered and answered and closed, closing all modals
        else if (
            isAppActive.value &&
            user.sibTimeMoment &&
            !user.sibTimeMoment.mustTrigger &&
            !user.sibTimeMoment?.activeInstanceId
        ) {
            closeModalIfOpen()
        }
    }

    // Function to close the modal if it's open
    async function closeModalIfOpen() {
        logger(debug)
        // Find the modal by ID
        const modal = await modalController.getTop()

        if (modal && modal.id === 'sibtime-moments-notification') {
            modalController.dismiss('sibtime-moments-notification')
        }
    }

    async function getSibTimeMoment() {
        logger(debug)
        return viewer.value?.sibTimeMoment
    }

    /*
     * Save the trigger time for the sibtime moment
     * @param {string} triggerTime - The trigger time for the sibtime moment
     */
    async function saveSibTimeMoment(triggerTime: string | undefined) {
        logger(debug, triggerTime)

        await updateSibTimeMoments(
            {
                triggerTime,
            },
            ['triggerTime']
        )
    }

    async function getFutherLevelProgress(userId: string) {
        logger(debug, userId)
        const userProgress = await getUserProgress(userId)

        if (userProgress) {
            const userLevelData = await fetchTabulatedProgress(userProgress)

            if (userLevelData.length === 0) return undefined
            const furthestLevelIndex = userLevelData
                .map((level) => level.furthestPage !== undefined)
                .lastIndexOf(true)
            return furthestLevelIndex
        }

        return undefined
    }

    async function openSibTimeMomentModal(index?: number) {
        const modal = await modalController.getTop()
        const module = index ? index + 1 : undefined
        if (!modal || modal.id !== 'sibtime-moments-notification') {
            const sibtimeMomentsNotification = await getRandomMoment(index)
            const sibTimeMoment = await modalController.create({
                id: 'sibtime-moments-notification',
                component: SibtimeMomentsNotification,
                backdropDismiss: false,
                cssClass: ['stack-modal', 'safe-area'],
                componentProps: {
                    sibTimeMoment: sibtimeMomentsNotification,
                    specificModule: module,
                },
            })

            sibTimeMoment.present()
            return sibTimeMoment
        }
        return undefined
    }

    /*
     * Get a random moment that the user has not seen yet
     * @param {number} module - The module to filter the moments
     */
    async function getRandomMoment(module?: number) {
        logger(debug)

        if (!viewer.value) throw new Error('Viewer not found')

        // Retrieve previous moments for the viewer
        const previousMoments =
            viewer.value.sibTimeMoment?.previousMoments ?? []

        // Filter out moments that the viewer has already seen
        const availableMoments = moments.filter(
            (moment) => !previousMoments.includes(moment.id)
        )

        let possibleMoments
        let userProgress = 0

        // If module is provided, filter by moments in the same module
        if (module !== undefined) {
            possibleMoments = availableMoments.filter(
                (moment) => moment.module === module
            )
        } else {
            // Filter moments based on user progress only if module is not specified
            userProgress = (await getFutherLevelProgress(viewer.value.id)) || 0

            possibleMoments = availableMoments.filter(
                (moment) => moment.module <= userProgress + 1
            ) // +1 to include the next module
        }

        // If no possible moments found, pick a random fallback
        if (possibleMoments.length === 0) {
            const fallbackMoments =
                module !== undefined
                    ? moments.filter((moment) => moment.module === module)
                    : moments.filter((moment) => {
                          return moment.module <= userProgress + 1
                      })

            // Return a random moment from the fallback moments
            return fallbackMoments[
                Math.floor(Math.random() * fallbackMoments.length)
            ]
        }

        // Select a random moment from the possible moments
        const randomMoment =
            possibleMoments[Math.floor(Math.random() * possibleMoments.length)]

        return randomMoment
    }

    /*
     * Update the user's already seen moments
     * @param {string} momentId - The moment ID to add to the user's already seen moments
     * @param {number} module - The module to filter the moments
     */
    async function updateUserMoments(momentId: string, module?: number) {
        logger(debug, momentId)

        let previousMoments = (
            viewer.value?.sibTimeMoment?.previousMoments ?? []
        ).slice()

        // Check if the momentId is already in the list (it means the user has already seen all moments on that context)
        if (previousMoments.includes(momentId)) {
            if (module !== undefined) {
                // Remove all moments that belong to the specified module
                previousMoments = previousMoments.filter((momentId) => {
                    const moment = moments.find((m) => m.id === momentId)
                    return moment && moment.module !== module
                })
            } else {
                // Clear all moments if no module is specified
                previousMoments = []
            }
        }

        if (!previousMoments.includes(momentId)) {
            previousMoments.push(momentId)
        }

        await updateSibTimeMoments(
            {
                previousMoments,
                activeInstanceId: instanceId,
                mustTrigger: false,
            },
            ['previousMoments', 'mustTrigger', 'activeInstanceId']
        )
    }

    async function getMomentDownloadURL(fileName: string) {
        return storage.ref().child('moments').child(fileName).getDownloadURL()
    }

    watch(
        () => viewer.value?.sibTimeMoment,
        () => {
            if (viewer.value) {
                handleUserSnapshot(viewer.value)
            }
        }
    )

    function deinitSibtimeMoments() {
        logger(debug)

        removeAllListeners()
    }
    return {
        initSibtimeMoments,
        getMomentDownloadURL,
        getSibTimeMoment,
        openSibTimeMomentModal,
        saveSibTimeMoment,
        updateUserMoments,
        deinitSibtimeMoments,
    }
}
